import React from "react";

export const UserContext = React.createContext({
  user: undefined,
  logout: () => {},
  login: () => {}
});

export const DocumentContext = React.createContext({
  cocuments: []
});
