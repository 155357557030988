/* eslint-disable no-restricted-globals */
import api from 'tca/api';

const KEY_USER_ID = 'userID';
const KEY_TOKEN = 'userToken';
const KEY_USER_EMAIL = 'userEmail';
const KEY_FETCH_PROFILE_CNT = 'userFetchProfileCnt';

class LocalStorage {

  clear() {
    self.localStorage.clear();
  }

  logout() {
    self.localStorage.removeItem(KEY_USER_ID);
    self.localStorage.removeItem(KEY_TOKEN);
    self.localStorage.removeItem(KEY_FETCH_PROFILE_CNT);
    api.deleteAuth();
  }

  get isLoggedIn() {
    return !!this.userId && !!this.authToken;
  }

  get userId() {
    return self.localStorage.getItem(KEY_USER_ID);
  }

  set userId(value) {
    self.localStorage.setItem(KEY_USER_ID, value);
  }

  get authToken() {
    return self.localStorage.getItem(KEY_TOKEN);
  }

  set authToken(value) {
    self.localStorage.setItem(KEY_TOKEN, value);
  }

  get userEmail() {
    return self.localStorage.getItem(KEY_USER_EMAIL);
  }

  set userEmail(value) {
    self.localStorage.setItem(KEY_USER_EMAIL, value);
  }
  
  get fetchProfileCnt() {
    return self.localStorage.getItem(KEY_FETCH_PROFILE_CNT);
  }

  set fetchProfileCnt(value) {
    self.localStorage.setItem(KEY_FETCH_PROFILE_CNT, value);
  }
}

const instance = new LocalStorage()

export default instance
