const USER_ROLE = {
  MASTER: "MASTER",
  CREDIT: "CREDIT",
  COLLECTION: "COLLECTION"
};

const USER_LEVEL = {
  L0: 0,
  L1: 1
};

class User {
  constructor(user) {
    this.userInfo = { ...user };
  }
  get email() {
    return this.userInfo && this.userInfo.email;
  }

  get name() {
    return (
      this.userInfo && this.userInfo.first_name + " " + this.userInfo.last_name
    );
  }

  get user() {
    return this.userInfo;
  }

  get id() {
    return this.userInfo && this.userInfo.id;
  }

  get role() {
    return this.userInfo && this.userInfo.role_id;
  }

  get level() {
    return this.userInfo && this.userInfo.level_id;
  }

  get location() {
    return this.userInfo && this.userInfo.location;
  }

  get cities() {
    return this.userInfo && this.userInfo.location;
  }

  get isProgramHead() {
    return true;
    return !!(
      this.userInfo &&
      this.userInfo.v2_role &&
      this.userInfo.v2_role.rolePrivileges &&
      this.userInfo.v2_role.rolePrivileges.filter(
        role => role.privilege === USER_ROLE.MASTER
      ).length === 1
    );
  }

  get isCreditHead() {
    return this.role === USER_ROLE.CREDIT && this.level === USER_LEVEL.L0;
  }

  get isCreditOps() {
    return this.role === USER_ROLE.CREDIT && this.level === USER_LEVEL.L1;
  }
  get isCollectionHead() {
    return this.role === USER_ROLE.COLLECTION && this.level === USER_LEVEL.L0;
  }
  get isCollectionOps() {
    return this.role === USER_ROLE.COLLECTION && this.level === USER_LEVEL.L1;
  }
}

export default User;
