import React, { Component, Suspense } from "react";
import socketIOClient from "socket.io-client";
import localStorage from "tca/utils/localStorage";
import { UserContext, DocumentContext } from "tca/contexts";
import api from "tca/api";
import { API_PROFILE, API_LOGOUT } from "tca/api/endpoints";
import LoaderError, { ErrorBoundary } from "tca/components/common/loaderError";
import { SnackbarProvider } from "notistack";
import IdleTimer from 'react-idle-timer';
// import withAppVersion from "tca/components/common/withAppVersion";
import User from "./user";
import { API_SFA_VERIFY_TOKEN } from "tca/api/endpoints";

const Login = React.lazy(() => import("tca/components/common/login"));
const Root = React.lazy(() => import("tca/components/root"));
const PublicRoot = React.lazy(() => import("tca/components/publicRoot"));
const ExtRoot = React.lazy(() => import("tca/components/extRoot"));
const SFARoot = React.lazy(() => import("tca/components/sfaRoot"));

const PUBLIC_ROOT_NAME = "/pub";
const EXT_ROOT_NAME = "/ext";
//const SFA_ROOT_NAME = "/sfa";

const CHAT_END_POINT = process.env.REACT_APP_CHAT_BASE_URL;
const CHAT_PATH = process.env.REACT_APP_CHAT_PATH;

const isPublicRoot = () => {
  return (window.location.pathname || "").includes(PUBLIC_ROOT_NAME);
};

const isExtRoot = () => {
  return (window.location.pathname || "").includes(EXT_ROOT_NAME);
};

const isSFARoot = () => {
  const source = getQueryStringValue('source');
  return source.toLocaleLowerCase() == 'sfa' ? true : false;
  //return (window.location.pathname || "").includes(SFA_ROOT_NAME);
};

const getQueryStringValue = (key) => {  
  return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));  
}

class Config extends Component {
  constructor(props) {
    super(props);
    let isLoggedIn = localStorage.isLoggedIn;
    this.state = {
      user: undefined,
      loading: isLoggedIn,
      success: true,
      timeout:1000 * 60 * 60,
      showModal: false,
      isTimedOut: false,
      err: null,
      source: ''
    };
    this.idleTimer = null
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { success: false };
  }

  logout = () => {
    if (localStorage.authToken) {
      api.auth
      .get(API_LOGOUT)
      .then(() => {})
      .catch(err => {});
    }
    localStorage.logout();
    this.setState({ user: undefined, loading: false, success: true, source: '' });
    if (this.state.source === 'SFA') {
      // send back to APP
      /*global Android*/
      /*eslint no-undef: "error"*/
      Android.signOut ();
    }
  };

  SFALogout = (err) => {
    localStorage.logout();
    this.setState({ user: undefined, loading: false, success: true, err });
  }

  login = (data, source= '') => {
    let {
      user,
      token,
      program_config,
      city,
      onboarding_documents,
      tranche_documents,
      privileges,
      user_privileges
    } = data;
    localStorage.userId = user._id;
    localStorage.authToken = token;
    this.setState({
      user: user,
      loading: false,
      success: true,
      program_config,
      privileges,
      user_privileges,
      city,
      onboarding_documents,
      tranche_documents,
      source
    });
  };

  handleOnActive = () => {
    this.setState({isTimedOut: false})
  }

  handleOnIdle = () => {
    const isTimedOut = this.state.isTimedOut
      if (isTimedOut) {
        this.logout()
      } else {
        this.setState({showModal: true})
        this.idleTimer.reset();
        this.setState({isTimedOut: true})
      }
  }

  render() {
    const {
      user,
      loading,
      success,
      program_config,
      city,
      onboarding_documents,
      privileges,
      tranche_documents,
      user_privileges
    } = this.state;
    if (loading) return <LoaderError className="min-vh-100" />;

    if (!success)
      return (
        <LoaderError className="min-vh-100" error retry={this.handleRetry} />
      );

    return (
      <DocumentContext.Provider
        value={{
          onboarding_documents,
          tranche_documents
        }}
      >
        <UserContext.Provider
          value={{
            user: new User(user),
            login: this.login,
            logout: this.logout,
            program_config,
            privileges,
            city,
            user_privileges
          }}
        >
          {!!user ? (
            <IdleTimer
              ref={ref => { this.idleTimer = ref }}
              timeout={this.state.timeout}
              onActive={this.handleOnActive}
              onIdle={this.handleOnIdle}
              onAction={this.handleOnActive}
              debounce={250}
            >
              <Root />
            </IdleTimer>
          ) : (
            isSFARoot() ? (
              <SFARoot err={this.state.err} />
            ) : (
              <Login
                className="min-vh-100 min-vw-100 d-flex align-items-center justify-content-center"
                login={this.login}
                logout={this.logout}
              />
            )
          )}
        </UserContext.Provider>
      </DocumentContext.Provider>
    );
  }

  emitLastUserLogin = userId => {
    this.socket = socketIOClient(CHAT_END_POINT, {
      path: CHAT_PATH,
      transports: ["websocket"]
    });
    this.scocketEventListener(userId);
  };

  scocketEventListener = userId => {
    if (!this.socket) return;
    this.socket.on("connect", () => {
      console.log(">>> last login socket connected on clinet side");
    });
    this.socket.on("error", err => {
      console.log(">>> error in connected socket", err);
    });

    this.doEmitEveryThreeMinute(userId);
  };

  doEmitEveryThreeMinute = userId => {
    this.socket.emit("heartbeat", userId);
    this.timerHandle = setTimeout(() => {
      this.doEmitEveryThreeMinute(userId);
    }, 3 * 60 * 1000);
  };

  clearTimer = () => {
    // Is our timer running?
    if (this.timerHandle) {
      // Yes, clear it
      clearTimeout(this.timerHandle);
      this.timerHandle = 0;
    }
  };

  componentDidMount() {
    const source = getQueryStringValue("source");
    if (source && source.toLocaleLowerCase()=='sfa') {
      this.fetchSFAProfile();
    } else {
      if (!localStorage.isLoggedIn) return;
      this.fetchProfile();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!!this.state.user) this.emitLastUserLogin(this.state.user.id);
    else this.clearTimer();
  }

  componentWillUnmount() {
    this.clearTimer();
  }
  
  fetchSFAProfile = () => {
    const token = getQueryStringValue("token");
    this.setState({ user: undefined, loading: true, success: false });
    let opt = window.location.pathname.split('/');
    
    api.authless
      .post(API_SFA_VERIFY_TOKEN, { token, gcd_id: opt[2] })
      .then(({ status, data = {} }) => {
        if (status !== 200) throw new Error();
        this.login(data, 'SFA');
      })
      .catch(err => {
        this.SFALogout('Invalid user/token');        
      });
  }

  fetchProfile = () => {
    this.setState({ user: undefined, loading: true, success: false })
      api.auth
      .get(API_PROFILE)
      .then(({ status, data = {} }) => {
        if (status !== 200) throw new Error();
        this.login(data);
      })
      .catch(err => {
        console.log('logout called from fetch profile');
        this.logout();
      });
  };

  handleRetry = async () => {
    if (window.caches) {
      try {
        let cache_keys = await window.caches.keys();
        await Promise.all(cache_keys.map(key => window.caches.delete(key)));
      } catch (err) {
        //error in removing caches
      }
    }
    if (window) {
      window.location.reload(true);
    }
  };
}

const App = props => {
  let renderComponent = <Config {...props} />;
  if (isPublicRoot()) {
    renderComponent = <PublicRoot {...props} />;
  } else if (isExtRoot()) {
    renderComponent = <ExtRoot {...props} />;
  }
  return (
    <ErrorBoundary className="min-vh-100">
      <Suspense fallback={<LoaderError className="min-vh-100" />}>
        <SnackbarProvider maxSnack={3}>{renderComponent}</SnackbarProvider>
      </Suspense>
    </ErrorBoundary>
  );
};

export default App;

// export default withAppVersion(App);
