import React, { Component, Fragment } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import JssProvider from "react-jss/lib/JssProvider";
import { createGenerateClassName } from "@material-ui/core/styles";
import MUIBaseline from "@material-ui/core/CssBaseline";
import Theme from "tca/css/muiTheme";
import Config from "tca";
import "tca/css/bootstrap/app.scss";
import { GoogleOAuthProvider } from "@react-oauth/google";

// theme
const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
});

const HealthComponent = () => {
  console.log('Rendering HealthComponent');
  return <h3>Status: 200 Working</h3>;
};

class App extends Component {
  render() {
    return (
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <JssProvider generateClassName={generateClassName}>
          <Fragment>
            <div>
              <Theme>
                <MUIBaseline />
                <Config />
                <Router>
                  <Switch>
                    {/* /health is accessible without authentication */}
                    <Route exact path="/health" component={HealthComponent} />
                    {/* Add more routes as needed */}
                  </Switch>
                </Router>
              </Theme>
            </div>
            {/* Add other components that need authentication here */}
          </Fragment>
        </JssProvider>
      </GoogleOAuthProvider>
    );
  }
}

export default App;
