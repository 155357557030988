import axios from "axios";
import localStorage from "tca/utils/localStorage";
import sessionStorage from "tca/utils/sessionStorage";
//testing

class API {
  constructor() {
    this._baseURL = process.env.REACT_APP_API_BASE_URL;
    this._authless = null;
    this._auth = null;
    this._sessionAuth = null;
    this._auth_blob = null;
    this._auth_buffer = null;
  }

  get baseURL() {
    return this._baseURL;
  }

  get authless() {
    if (!this._authless) {
      this._authless = axios.create({
        baseURL: this.baseURL,
      });
    }
    return this._authless;
  }

  deleteAuth() {
    this._auth = null;
  }

  get auth() {
    if (!this._auth) {
      this._auth = axios.create({
        baseURL: this.baseURL,
        headers: this.authHeaders,
      });
      this.addResponseInterceptopr(this._auth);
    }
    return this._auth;
  }

  get bufferAuth() {
    if (!this._auth_buffer) {
      this._auth_buffer = axios.create({
        baseURL: this.baseURL,
        responseType: "arraybuffer",
        headers: this.authHeaders,
      });
    }
    return this._auth_buffer;
  }

  get blobAuth() {
    if (!this._auth_blob) {
      this._auth_blob = axios.create({
        baseURL: this.baseURL,
        responseType: "blob",
        headers: this.authHeaders,
      });
    }
    return this._auth_blob;
  }

  deleteSession() {
    this._sessionAuth = null;
  }

  get sessionAuth() {
    if (!this._sessionAuth) {
      this._sessionAuth = axios.create({
        baseURL: this.baseURL,
        headers: this.sessionAuthHeader,
      });
    }
    return this._sessionAuth;
  }

  addResponseInterceptopr(axiosInstance) {
    if (!axiosInstance) return null;
    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        let errorCode = error.response && error.response.status;
        if (errorCode === 401) {
          if (window) window.location = "/";
        }
        return Promise.reject(error);
      }
    );
  }

  get authHeaders() {
    return {
      "X-Token": localStorage.authToken,
    };
  }

  get sessionAuthHeader() {
    return {
      "X-Token": sessionStorage.authToken,
    };
  }

  showError(err, enqueueSnackbar, options = {}) {
    let errorMessage =
      typeof err === "string"
        ? err
        : err && err.response && err.response.data && err.response.data.msg;
    errorMessage && enqueueSnackbar && enqueueSnackbar(errorMessage, options);
  }
}

const api = new API();

export default api;
